import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'radio-input-type',
  templateUrl: './radio-input.type.html',
  styleUrls: ['./radio-input.type.css']
})
export class RadioInputType extends FieldType<FieldTypeConfig> {
  constructor(public translate:TranslateService) {
    super();
  }

  getLabel(option:{value:string, label:string}):string {
    let placeholder = this.translate.instant(option.label);
    if (this.props.required){
      placeholder += '*';
    }
    return placeholder;
  }
}
