import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RegistrationComponent } from './registration.component';
import { TextInputType } from './formly/text-input/text-input.type';
import { RadioInputType } from './formly/radio-input/radio-input.type';
import { SelectInputType } from './formly/select-input/select-input.type';

@NgModule({
  declarations: [
    RegistrationComponent,
    TextInputType,
    RadioInputType,
    SelectInputType
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    RegistrationComponent,
  ],
})
export class RegistrationModule { }
