// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
    --background: var(--ion-color-dark);
    --color: var(--text-color-light);
}

.system-info {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: repeat(2, 1fr);
    gap: 0 8px;
    height: 48px;
    padding: 5px 8px;
}

.system-info ion-icon {
    grid-row: 1 / -1;
    font-size: 1.5rem;
    margin: auto;
}

.edge-name {
    font-size: 12px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    margin: auto 0;
}

.edge-id {
    font-size: 12px;
    margin: auto auto auto 0;
}

.system-state {
    grid-row: 1 / -1;
    font-size: 16px;
    margin: auto 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/header/header.component.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,gCAAgC;IAChC,kCAAkC;IAClC,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":["ion-toolbar {\n    --background: var(--ion-color-dark);\n    --color: var(--text-color-light);\n}\n\n.system-info {\n    display: grid;\n    grid-template-columns: auto auto;\n    grid-template-rows: repeat(2, 1fr);\n    gap: 0 8px;\n    height: 48px;\n    padding: 5px 8px;\n}\n\n.system-info ion-icon {\n    grid-row: 1 / -1;\n    font-size: 1.5rem;\n    margin: auto;\n}\n\n.edge-name {\n    font-size: 12px;\n    text-wrap: nowrap;\n    text-overflow: ellipsis;\n    margin: auto 0;\n}\n\n.edge-id {\n    font-size: 12px;\n    margin: auto auto auto 0;\n}\n\n.system-state {\n    grid-row: 1 / -1;\n    font-size: 16px;\n    margin: auto 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
