// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#parent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#login {
  margin: auto 0;
  padding: 2rem;
}

form > * {
  display: block;
  margin-bottom: 10px;
}

input[type=email], input[type=password] {
  height: 3rem;
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--ion-color-medium-shade);
  border-radius: var(--default-border-radius);
}

ion-button {
  height: 3rem;
}

.forgotPasswordLink {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/index/login.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,cAAA;EACA,aAAA;AACJ;;AAEA;EACI,cAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,+CAAA;EACA,2CAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":["#parent {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n#login {\n    margin: auto 0;\n    padding: 2rem;\n}\n\nform > * {\n    display: block;\n    margin-bottom: 10px;\n}\n\ninput[type=\"email\"], input[type=\"password\"] {\n    height: 3rem;\n    width: 100%;\n    padding: 1rem;\n    border: 1px solid var(--ion-color-medium-shade);\n    border-radius: var(--default-border-radius);\n}\n\nion-button {\n    height: 3rem;\n}\n\n.forgotPasswordLink {\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
