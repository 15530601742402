// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#parent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container {
  max-width: 800px;
  margin: auto 0;
  padding: 1rem 2rem;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 1200px) {
  .form-container {
    padding-top: 0;
  }
}
ion-button {
  margin-top: 0.5rem;
  width: 100%;
  height: 3rem;
}

.success-container {
  max-width: 900px;
  margin: auto 0;
  padding: 1rem 2rem;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.success-container ion-icon {
  width: 163px;
  height: 163px;
  padding-bottom: 20px;
}

.logo {
  max-width: 300px;
  padding-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/registration/registration.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,uBAAA;EAAA,kBAAA;AACJ;;AAEA;EACI;IACI,cAAA;EACN;AACF;AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,YAAA;EACA,aAAA;EACA,oBAAA;AAAR;;AAIA;EACI,gBAAA;EACA,iBAAA;AADJ","sourcesContent":["#parent {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.form-container {\n    max-width: 800px;\n    margin: auto 0;\n    padding: 1rem 2rem;\n    width: fit-content;\n}\n\n@media (max-width: 1200px) {\n    .form-container {\n        padding-top: 0;\n    }\n}\n\nion-button {\n    margin-top: 0.5rem;\n    width: 100%;\n    height: 3rem;\n}\n\n.success-container {\n    max-width: 900px;\n    margin: auto 0;\n    padding: 1rem 2rem;\n    width: fit-content;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n\n    ion-icon {\n        width: 163px;\n        height: 163px;\n        padding-bottom: 20px;\n    }\n}\n\n.logo {\n    max-width: 300px;\n    padding-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
