// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    margin-bottom: 0.5rem;
}

input {
    height: 3rem;
    width: 100%;
    padding: 1rem;
    border: 1px solid var(--ion-color-dark-tint);
    border-radius: var(--default-border-radius);
}

input:disabled {
    color: var(--ion-color-medium-tint);
    border-color: var(--ion-color-medium-tint);
}

input:focus-visible {
    outline: 2px solid var(--ion-color-primary);
}

.ng-touched.ng-invalid {
    outline: 2px solid var(--ion-color-danger);
}

.required-msg {
    display: none;
}

input.ng-touched.ng-invalid ~ .required-msg {
    display: block;
    color: var(--ion-color-danger);
}`, "",{"version":3,"sources":["webpack://./src/app/registration/formly/text-input/text-input.type.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,4CAA4C;IAC5C,2CAA2C;AAC/C;;AAEA;IACI,mCAAmC;IACnC,0CAA0C;AAC9C;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,8BAA8B;AAClC","sourcesContent":[".container {\n    margin-bottom: 0.5rem;\n}\n\ninput {\n    height: 3rem;\n    width: 100%;\n    padding: 1rem;\n    border: 1px solid var(--ion-color-dark-tint);\n    border-radius: var(--default-border-radius);\n}\n\ninput:disabled {\n    color: var(--ion-color-medium-tint);\n    border-color: var(--ion-color-medium-tint);\n}\n\ninput:focus-visible {\n    outline: 2px solid var(--ion-color-primary);\n}\n\n.ng-touched.ng-invalid {\n    outline: 2px solid var(--ion-color-danger);\n}\n\n.required-msg {\n    display: none;\n}\n\ninput.ng-touched.ng-invalid ~ .required-msg {\n    display: block;\n    color: var(--ion-color-danger);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
