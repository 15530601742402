import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, AfterViewInit, HostListener, DoCheck } from '@angular/core';

@Component({
  selector: 'index-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() text: string = '';
  @Input() buttonText: string = '';
  @Output() buttonClickedEvent: EventEmitter<void> = new EventEmitter<void>();

  onButtonClicked() {
    this.buttonClickedEvent.emit();
  }
}
