// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-light);
  padding: 2rem;
}

@media (min-width: 1200px) {
  :host {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/banner/banner.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,gBAAA;EACA,SAAA;EACA,uCAAA;EACA,6BAAA;EACA,aAAA;AACJ;;AAEA;EACI;IACI,aAAA;EACN;AACF","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n    position: sticky;\n    bottom: 0;\n    background-color: var(--ion-color-dark);\n    color: var(--ion-color-light);\n    padding: 2rem;\n}\n\n@media (min-width: 1200px) {\n    :host {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
