// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
    padding-left: 0.5rem;
}

div:not(:last-child) {
    margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/registration/formly/radio-input/radio-input.type.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["label {\n    padding-left: 0.5rem;\n}\n\ndiv:not(:last-child) {\n    margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
