// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add a separator bar before every oe-flat-widget-line that is preceeded by another oe-flat-widget-line. */
:host ::ng-deep {
    :is(oe-flat-widget-line) + oe-flat-widget-line {
        padding-top: 8px;
        border-top: 1px solid var(--ion-color-medium-shade);
    }
}

:host {
    border-radius: var(--default-border-radius);
    border: 1px solid var(--ion-color-medium);
    box-shadow: none;
    margin: 8px;
    padding: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 8px 0px;
}

.header {
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px 8px;

    :is(h6) {
        margin: auto 0px;
    }

    .info {
        margin-left: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/genericComponents/flat/flat.css"],"names":[],"mappings":"AAAA,2GAA2G;AAC3G;IACI;QACI,gBAAgB;QAChB,mDAAmD;IACvD;AACJ;;AAEA;IACI,2CAA2C;IAC3C,yCAAyC;IACzC,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;;IAEZ;QACI,gBAAgB;IACpB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["/* Add a separator bar before every oe-flat-widget-line that is preceeded by another oe-flat-widget-line. */\n:host ::ng-deep {\n    :is(oe-flat-widget-line) + oe-flat-widget-line {\n        padding-top: 8px;\n        border-top: 1px solid var(--ion-color-medium-shade);\n    }\n}\n\n:host {\n    border-radius: var(--default-border-radius);\n    border: 1px solid var(--ion-color-medium);\n    box-shadow: none;\n    margin: 8px;\n    padding: 16px;\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    gap: 8px 0px;\n}\n\n.header {\n    height: 32px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 0px 8px;\n\n    :is(h6) {\n        margin: auto 0px;\n    }\n\n    .info {\n        margin-left: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
