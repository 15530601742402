// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 16px;
}

.push-end {
  margin-top: auto;
}

ion-split-pane {
  --border: none;
}

ion-menu {
  --side-max-width: 300px;
}
ion-menu img {
  display: block;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

ion-item {
  margin: 0px 12px 4px 12px;
}

.index-navbar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 50px 50px 50px;
  color: var(--ion-color-light);
}
.index-navbar img {
  width: 100%;
}
.index-navbar ion-button {
  height: 3rem;
  width: 100%;
}
.index-navbar p {
  padding-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACA,cAAA;AACA;;AAEA;EACI,uBAAA;AACJ;AACI;EAEI,cAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;AAAR;;AAIA;EACI,yBAAA;AADJ;;AAIA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,6BAAA;EACA,6BAAA;AADJ;AAGI;EACI,WAAA;AADR;AAII;EACI,YAAA;EACA,WAAA;AAFR;AAKI;EACI,oBAAA;AAHR","sourcesContent":["nav {\n    display: flex;\n    flex-direction: column;\n    min-height: 100%;\n    padding-top: 16px;\n}\n\n.push-end {\n    margin-top: auto;\n}\n\nion-split-pane {\n--border: none;\n}\n\nion-menu {\n    --side-max-width: 300px;\n\n    img {\n        // Block allows centering the image using margins\n        display: block;\n        width: 55%;\n        margin-left: auto;\n        margin-right: auto;\n    }\n}\n\nion-item {\n    margin: 0px 12px 4px 12px;\n}\n\n.index-navbar {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 100px 50px 50px 50px;\n    color: var(--ion-color-light);\n    \n    img {\n        width: 100%;\n    }\n    \n    ion-button {\n        height: 3rem;\n        width: 100%;\n    }\n    \n    p {\n        padding-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
